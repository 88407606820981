import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "InstarVision Surveillance Center",
  "path": "/Software/Android/InstarVision/Wizard/Install/Others/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Um mit unserer Android App InstarVision auf Ihre IP-Kamera zuzugreifen, empfehlen wir Ihnen, zuerst die neueste Version aus dem App Store herunterzuladen. Verwenden Sie das Suchwort Instar, um unsere App im Geschäft zu finden.",
  "image": "./P_SearchThumb_InstarVision_Android.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Android.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Android-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "android-app-installation-wizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#android-app-installation-wizard",
        "aria-label": "android app installation wizard permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Android App Installation Wizard`}</h2>
    <h3 {...{
      "id": "add-a-new-p2p-camera-others-models",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-new-p2p-camera-others-models",
        "aria-label": "add a new p2p camera others models permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a New P2P Camera (`}<em parentName="h3">{`Others Models`}</em>{`)`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/495f45a508df3fe2943634ac43dbc4d2/81315/Android_Wizard_Installation_Others_d01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACdklEQVQ4y42SS24TQRCGZ2mcZEFWieOMHdsozHjcj+rHTHfP0zZyLI8dOwkhK7ziAqxBCC7ABbJAYs9ROARix4YjoGmTCCIh/Olvtbpbf1Wpqp1Go+F53v7+fq1Wq1tarZbv+0EwaLrt09OnvV6vXq/v7u7u7Ozs7e25rut5HkLYPek6nud1u908zwHAdd1Op9NqtY6bR83WySE9O33q9XpdY0xRFFrrLMsY483mUfPYPWSl4/t+v9/HGCOE+ncEQT+oVnXj+z7GOAxDIYS0EEIq1wA51EIs9CEEACilUso8z5MkSdM0yzJjDGMMABzyB9hyf0QIGWOeW66urq6vr29ubi4uLs7Pz9fr9Xg8du5SAoVKQOG+FowxAMzn89lstlwuV6vVwjKfzxeLhZTSmlEQa5VnSRqb7A5KMEFIyrAsy+VyuVgsLi8vV6vVJkpZlnEcO5QJmk4hnUIyoelUyqoxQAliCjHFgAohGGOc89FoNJlMwjAEAGZxmJBmONXFmc7HaTFO0lQpRTDCLMQgfrcBV/tgMAiCYNOUza0DMgqHM1GUYTKMJFdaK61t5gixKgrnXHDObXIheJIkWhulososhZieTbIkVlGklNJaK6UoJRgkBk4IERywKgbqGWc0itR4OBwPizRNCSEOxgThh2wGZ0U5AwgNDWPGQEWRiIcknsRGU0r/mvO/YEAZACGEM+AqBTMSoipqGzMGxikXmAKmDBiXMtw8bJc5eMK9Nlgxrw1+Z1szxejk/M3B+nN7fdte3x6+/HT84iOhQAj+r5lSFBy8+lJ797Px/tvRh++P3v54/PprNQiMtyqbVL8EbSDYypb9C9NR3uFSRSlAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/495f45a508df3fe2943634ac43dbc4d2/e4706/Android_Wizard_Installation_Others_d01.avif 230w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/d1af7/Android_Wizard_Installation_Others_d01.avif 460w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/7f308/Android_Wizard_Installation_Others_d01.avif 920w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/e1c99/Android_Wizard_Installation_Others_d01.avif 1380w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/6e1e4/Android_Wizard_Installation_Others_d01.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/495f45a508df3fe2943634ac43dbc4d2/a0b58/Android_Wizard_Installation_Others_d01.webp 230w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/bc10c/Android_Wizard_Installation_Others_d01.webp 460w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/966d8/Android_Wizard_Installation_Others_d01.webp 920w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/445df/Android_Wizard_Installation_Others_d01.webp 1380w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/87a8c/Android_Wizard_Installation_Others_d01.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/495f45a508df3fe2943634ac43dbc4d2/81c8e/Android_Wizard_Installation_Others_d01.png 230w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/08a84/Android_Wizard_Installation_Others_d01.png 460w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/c0255/Android_Wizard_Installation_Others_d01.png 920w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/b1001/Android_Wizard_Installation_Others_d01.png 1380w", "/en/static/495f45a508df3fe2943634ac43dbc4d2/81315/Android_Wizard_Installation_Others_d01.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/495f45a508df3fe2943634ac43dbc4d2/c0255/Android_Wizard_Installation_Others_d01.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[01]`}</strong>{` Start by entering the `}<strong parentName="li">{`Cameras`}</strong>{` menu and select the `}<strong parentName="li">{`Installation Wizard`}</strong>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[02]`}</strong>{` You can find the `}<strong parentName="li">{`QR Code`}</strong>{` in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/Remote_Access/"
        }}>{`WebUI`}</a>{` (A), printed on the label of your camera (B) or on the package.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5e01cff66c9727e91bd5130454f113fa/81315/Android_Wizard_Installation_Others_d02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVQ4y33TS0/iUBgGYPaa2esEalITEwUB8QKttNAGymocMqhBMYGgmFgdMzLKLIaaiKNyKYXSntOrXNz5J8aN/2L8F85+Qgkrmnn2b873fuccB4qiCIKsr68Hg0GXyzUzMzM79nF2dnFxcX5+/oNlenp6amrK6XQuLCwsLS0hCOLY2vqUTCb39tL7+/vb26lUKrVt2dnZ+ZJKud1uFEWj0SiGYQiCoCg6NzfncrmcTucw3Go1ZVkCQIYQapqm67pp6fX6AACKojY2Nvx+v8fjcY95xhyCIIhiR7LIFgAAhFDXdVEUGYaJRCJra2s+n88/wTHKQIsypqqqruudTicWi+E4vrKy4vV6fROGYQhBt9vt9/uDwaA3pmlap9NhGIYkSZqmA4HAZN7RaNREsQ0AMAzj8fHRNE3d0ut1ZRnEYnEcx0dj25xcqVRqtSrP85Ik6WOGYfT7fQghwzDhcHh1ddU+XC6Xb28r9Xq13W6pqmYYhmkapmkOBgNFVRKJxObmJkVR9mNfX3O1epXnG+12W1VV0zQNyzCsKAzD/G9hPN94fn5+enoa7dwY6/V6EMJEIkEQBIZhfr/fJgwheP/7/vb2RxTbgsAriqJpw87dbhcAMOpMkqTt4Q6Ou359fX15+f3wcH9/f8fz9VZLkCTJNE1Jkkbh5eVl+7FZli39KHFcuVz+yXFc5ebm7u6XIPCyLDebzXg8jmEYQRCBQMDmhR0VjkqlEsdx34bOz8+/FosX1epDrVbluDJN08FgMBQK2XfO5bIse1IsXhQKhePjQj6fPzzMX15+v7q6PDs7pSgKx3GvxSaczWULxwWWZc+GTk8sR0eH2Vx2N70bJsKjhdnfc3ovnTk4yGQyyeRnJsHQNBWJkmSEiJAEjgWxUIggiNGvnOz8D3jmeqJ/UpL1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e01cff66c9727e91bd5130454f113fa/e4706/Android_Wizard_Installation_Others_d02.avif 230w", "/en/static/5e01cff66c9727e91bd5130454f113fa/d1af7/Android_Wizard_Installation_Others_d02.avif 460w", "/en/static/5e01cff66c9727e91bd5130454f113fa/7f308/Android_Wizard_Installation_Others_d02.avif 920w", "/en/static/5e01cff66c9727e91bd5130454f113fa/e1c99/Android_Wizard_Installation_Others_d02.avif 1380w", "/en/static/5e01cff66c9727e91bd5130454f113fa/6e1e4/Android_Wizard_Installation_Others_d02.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5e01cff66c9727e91bd5130454f113fa/a0b58/Android_Wizard_Installation_Others_d02.webp 230w", "/en/static/5e01cff66c9727e91bd5130454f113fa/bc10c/Android_Wizard_Installation_Others_d02.webp 460w", "/en/static/5e01cff66c9727e91bd5130454f113fa/966d8/Android_Wizard_Installation_Others_d02.webp 920w", "/en/static/5e01cff66c9727e91bd5130454f113fa/445df/Android_Wizard_Installation_Others_d02.webp 1380w", "/en/static/5e01cff66c9727e91bd5130454f113fa/87a8c/Android_Wizard_Installation_Others_d02.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5e01cff66c9727e91bd5130454f113fa/81c8e/Android_Wizard_Installation_Others_d02.png 230w", "/en/static/5e01cff66c9727e91bd5130454f113fa/08a84/Android_Wizard_Installation_Others_d02.png 460w", "/en/static/5e01cff66c9727e91bd5130454f113fa/c0255/Android_Wizard_Installation_Others_d02.png 920w", "/en/static/5e01cff66c9727e91bd5130454f113fa/b1001/Android_Wizard_Installation_Others_d02.png 1380w", "/en/static/5e01cff66c9727e91bd5130454f113fa/81315/Android_Wizard_Installation_Others_d02.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5e01cff66c9727e91bd5130454f113fa/c0255/Android_Wizard_Installation_Others_d02.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[03]`}</strong>{` Use the app to scan the QR code from your camera or camera package. If your camera does not have a QR code, please manually select the camera model in the next step.`}</li>
      <li parentName="ul"><strong parentName="li">{`[04]`}</strong>{` Select your camera model, e.g. `}<strong parentName="li">{`IN-8015FullHD`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/86149dea54dec4d5236b0fa94da1b2d1/81315/Android_Wizard_Installation_Others_d03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACa0lEQVQ4y42SzW7TQBDHfW4Kp0qoauLYjWmTrL27s98mH/5IAk7TSJFaBEicOAA9gwSPwZkn4FiJQ9+iR3gd5N3GhCIhfrIs27N/z8x/xkMIhWG4v7/f2hIEAUIIxXHbD0/7/SiK3Pe9vb1Wq+X7/mAwwBh3jyPv5OQkiqI8zwHA9/0gCLrdbqd91O6Gh/DstN/v9Y6LomCM+b4fhmE3CDqdTrvjH/JzD1mwRQghpYzj2CaOUYybaJIkaEtsQQn2wEIpBYDJZDIejxlj8CfUAvehngsQQtwhQkhzb0L3nsmWWowxzrJsNpsxxpRSxhgpZZONMdaImxpddZ57qapqPB5zzqWUSimtNeecUiqEyLKsLMv5fF6W5Ww2K4pisViUZam1rsWcc2MM51wIwTlnjAkhtNYAYNuheZ4XRTG15HmeZZngDFzZlFJlERbGGLdQSihXeLQgO6bYNhPMDRZP7sRSSs65Usr56MSEEG3SyxevLi4uN5vNcrlcr9dVVVFCYl0OzeK3WGvtkkuL1tp2xGZFVubTNE3dT+/8A6AAHiEkTdPpdLo7wboKrRljGPhQTAaqxGC72HpOgBMm6iUxxoxGI9eSs835Z73Q683l+eZ5tTxbrc5Wq9V6vc7zbKjKYVrVYimlkEpqo7SRSgMXQirGGCFECPF0Mc+zqZS1/1prY4wQAjOFufHsxlCenIq4ByiCYY+jHkOPm5VKkgRjTHaghAIlQN2oCOm8/HLw/qZzde1fXR+8uzl6/ZUCc002E/qbO/Hhm28PPv70P9+2P90+/PDj0dvvZMehf4opBRIDRiSpL8AIcEz/g19A59ha3WkHTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86149dea54dec4d5236b0fa94da1b2d1/e4706/Android_Wizard_Installation_Others_d03.avif 230w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/d1af7/Android_Wizard_Installation_Others_d03.avif 460w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/7f308/Android_Wizard_Installation_Others_d03.avif 920w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/e1c99/Android_Wizard_Installation_Others_d03.avif 1380w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/6e1e4/Android_Wizard_Installation_Others_d03.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/86149dea54dec4d5236b0fa94da1b2d1/a0b58/Android_Wizard_Installation_Others_d03.webp 230w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/bc10c/Android_Wizard_Installation_Others_d03.webp 460w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/966d8/Android_Wizard_Installation_Others_d03.webp 920w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/445df/Android_Wizard_Installation_Others_d03.webp 1380w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/87a8c/Android_Wizard_Installation_Others_d03.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/86149dea54dec4d5236b0fa94da1b2d1/81c8e/Android_Wizard_Installation_Others_d03.png 230w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/08a84/Android_Wizard_Installation_Others_d03.png 460w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/c0255/Android_Wizard_Installation_Others_d03.png 920w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/b1001/Android_Wizard_Installation_Others_d03.png 1380w", "/en/static/86149dea54dec4d5236b0fa94da1b2d1/81315/Android_Wizard_Installation_Others_d03.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/86149dea54dec4d5236b0fa94da1b2d1/c0255/Android_Wizard_Installation_Others_d03.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[05]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[06]`}</strong>{` Select wether your camera should be connected via `}<strong parentName="li">{`WiFi`}</strong>{` or if you will be using an Ethernet cable.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/728dc7e2753546c708bd08bec3a54bed/81315/Android_Wizard_Installation_Others_d04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACtUlEQVQ4y32Ry27TQBSGvaAbogoWFSFunfjSUBw7c5/xOLYTxzEQ2qgKjUnDK6DuuKypaIFKsEC8DxIvgcRrVLADFdnTppGQ+GSNRj7zn8t/NM/zLMuq1Wo3rzBN0/O8brdrNE3XdR3HWYZqtVqz2ex0OgCAluVo7Xbbsqw0TTHGhmFYlmUYhq43GlvNOnp0775r2/ZwOEQIGYZhmqZhNHW9oW8Zd9m+hhDinAdBwBiDEAIACCGMMcY5ptzzPN/3hRCcc4wxQghjTAgBAHR8oEkp4zgOgiBJkr29vfF4nKZpFEWDwSDqhQihIAj6V2RZps4oijBGGoQwSZI4jvv9fpqmeZ73+/24AkKIEJpMJkVRzGazw8PDxWIxryiKglKq9Xq9+Xx+cHBQFMV0OlXn04okSXzfD8Nwd3d3PB6PRqM8z8cVYRgCULWdZdlgMFD9DCvUOymlKq4AAMAKhBBjjBCiAQAwxowxjDGEsLsCAAD9A8ZY13XbtsMwLN2OKpThjDFKKSFEeatYra82appmEASlmFLKORdCLJ8qvboLIaIoEhXqD2PscmaMcRiGURTlec4Yq9fr6+vrm5uby0QqyhhTA6rUnHMIYSnmnI+y4XR/EvckxRD4Hccyl08JIUIIQsjOzg4hBEIYBAGltBRfjsJkl4YekT4JIY+w6C1nllImScI5VyZDCKWUKlSKMexuHH1bO71ofPjZOPu1dvLn9qvvkDAEAcZl2WE6GGVDwdmjhw8YJZxRZaGmJms/PmrO3m0Xx9vFcWt26kxeIkwQujSZcsGEhJSLMKZcQESuKyOEiLdNOy3SMYlrlhfPud4N7G48/3rj5KJxdn7n/fna29+3Xv+AVWptub6y1Op3vWXgTF5sPftiLz7ai0/G4rP55A1CeEX8X4jXpm6LuFVfbot2bNX2X/VU4/UqNU9oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/728dc7e2753546c708bd08bec3a54bed/e4706/Android_Wizard_Installation_Others_d04.avif 230w", "/en/static/728dc7e2753546c708bd08bec3a54bed/d1af7/Android_Wizard_Installation_Others_d04.avif 460w", "/en/static/728dc7e2753546c708bd08bec3a54bed/7f308/Android_Wizard_Installation_Others_d04.avif 920w", "/en/static/728dc7e2753546c708bd08bec3a54bed/e1c99/Android_Wizard_Installation_Others_d04.avif 1380w", "/en/static/728dc7e2753546c708bd08bec3a54bed/6e1e4/Android_Wizard_Installation_Others_d04.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/728dc7e2753546c708bd08bec3a54bed/a0b58/Android_Wizard_Installation_Others_d04.webp 230w", "/en/static/728dc7e2753546c708bd08bec3a54bed/bc10c/Android_Wizard_Installation_Others_d04.webp 460w", "/en/static/728dc7e2753546c708bd08bec3a54bed/966d8/Android_Wizard_Installation_Others_d04.webp 920w", "/en/static/728dc7e2753546c708bd08bec3a54bed/445df/Android_Wizard_Installation_Others_d04.webp 1380w", "/en/static/728dc7e2753546c708bd08bec3a54bed/87a8c/Android_Wizard_Installation_Others_d04.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/728dc7e2753546c708bd08bec3a54bed/81c8e/Android_Wizard_Installation_Others_d04.png 230w", "/en/static/728dc7e2753546c708bd08bec3a54bed/08a84/Android_Wizard_Installation_Others_d04.png 460w", "/en/static/728dc7e2753546c708bd08bec3a54bed/c0255/Android_Wizard_Installation_Others_d04.png 920w", "/en/static/728dc7e2753546c708bd08bec3a54bed/b1001/Android_Wizard_Installation_Others_d04.png 1380w", "/en/static/728dc7e2753546c708bd08bec3a54bed/81315/Android_Wizard_Installation_Others_d04.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/728dc7e2753546c708bd08bec3a54bed/c0255/Android_Wizard_Installation_Others_d04.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[07]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[08]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/81315/Android_Wizard_Installation_Others_d05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACzElEQVQ4y12SzW7TQBDH/QBJKiEVVBLXJU0bN117v7xex44/6g9o1ThrO20PcEAgJKRSOFWqKsQLcOXIgRviAdoDAsQTtBck4HFQvCVE/E4rj/8z858ZRdf1brfbqmk0GktLS+12ezAYAGDcaWu6vtXr9ZrNZqvVajabjUZjdVXTdR0AsLreV/r9fq/Xi+MYY6xpWrfbXVtbUzvtztp6Gz/Y6OsbvV4QhowxGdU0TVXVjqrepfuKYRgIIfsvGGNagzEGBgAAzKOMMYwxIYRSSggBACgQwtFolGVZmqZJksRxnCRJlmVBEEAIKaVRFGU1aY2MpmnKOVds287zXAiR53lRFPv7+0KIqqrKsuScu657dHSU53lVVdPptCxLIURRFIeHh1EUKWEYTqfT8Xg8rRmPx5PJRAhxcHAQ1larqhJClGUpawghJpNJURQ7OzuK4zhZlslSEpk4yzJpNQiC4XCYJInMuLe3NxwOfd93XVcxTRMAYC4AIfzv7TgOY4xSKj8af1EQQhhjVCMfEEKMsW3b8s0YC8PQdV25gkUUjHEcx57nyWVQSn3fD8PQ8zyZkRAi+wcAzMtIZmLf9x3H4ZzLBY5GI+kTYywrx3G8u7vLGLMsC0L4T4wQMk1Tbl8ydzv/iXMeRZF0IU39E0u3lFKZ27KsxfZk25zzIAhmQx66CEJCyMwzQsiyLNmwPL1FMYTQdV05lDRNZ4d2/wHbyS2bY1m5FjBCKUTydi1C6FzMbdsbjfwg5JzPJuMFnnjmeAFC8GZV1BxYRh8NNuH2JgWbxNAXp0rNATH6EGyh7U0T063wAFJ+I0bQXHnysXn6Sz27Vs+uW6c/bz+/vNFhQoytlUfvl89/b7y+0t9ctc9/3Dr+Dq0hgoZSbxutPny3fPxVPb5QX1wuH3/pPP5wcweEELitHb5defnt3snF+qvLzsnnlaefkOVgBP8AZ6TxLl3vvMIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/e4706/Android_Wizard_Installation_Others_d05.avif 230w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/d1af7/Android_Wizard_Installation_Others_d05.avif 460w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/7f308/Android_Wizard_Installation_Others_d05.avif 920w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/e1c99/Android_Wizard_Installation_Others_d05.avif 1380w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/6e1e4/Android_Wizard_Installation_Others_d05.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/a0b58/Android_Wizard_Installation_Others_d05.webp 230w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/bc10c/Android_Wizard_Installation_Others_d05.webp 460w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/966d8/Android_Wizard_Installation_Others_d05.webp 920w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/445df/Android_Wizard_Installation_Others_d05.webp 1380w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/87a8c/Android_Wizard_Installation_Others_d05.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/81c8e/Android_Wizard_Installation_Others_d05.png 230w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/08a84/Android_Wizard_Installation_Others_d05.png 460w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/c0255/Android_Wizard_Installation_Others_d05.png 920w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/b1001/Android_Wizard_Installation_Others_d05.png 1380w", "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/81315/Android_Wizard_Installation_Others_d05.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fcdb97242ea9fdf4e47ba4c2c7aac3f3/c0255/Android_Wizard_Installation_Others_d05.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[09]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[10]`}</strong>{` Once you `}<a parentName="li" {...{
          "href": "/en/Quick_Installation/Set_Up_A_Wireless_Connection/#plugplay-wps"
        }}>{`activated the WPS mode`}</a>{` on both your camera and router click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3438474753da16d4f5dbe942f095b97b/81315/Android_Wizard_Installation_Others_d06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkklEQVQ4y3VSv2/TQBT23DAwVKht6iR2Gifxj7t7d+ez7xISxW5Vq6aiomnikQ6IBVYGQKqE1KGq4G/oAisbO38HMCJGGFmQUH2Nmwz99GTd3fe+98vPcF3Xtu1arba2QLPZdF3X8/3tRqvb7XY6nYqq1WqNRqPX62GMm9aO4ThOu90ej8eEENM0W61Wo9Go17e2zNYmZE6317btVba5Xa/Xt81N9sjwPA8AhBCcc0IIrcCYFyDXdX3fF0KEYQgLMEYRwq4fGJTS4XA4Ho9HC+hzMkkYpQBECDEajR6WmEwm+jsYDACIkaZpURRHR0dFUUyn0/l8Pp1OT05OiqJI0xQhlOf5bDY7Pj4uFtA+jLHrzJxzxliapru7u3t7e2ma6hfGGEIoiqI8z7Ms0w77+/tZlkkpEUIGvhtkAX1FCAGAvup3g9wB7VcpCSEIIcdxhBC3YoyxlFIpxRhbVnLOtRPnPAxDSinGOAgCfaCU3mQWQsRxrL0BQCmVJImUUgcalND9K6WiKBJCRFF0I1ZK5XmeJAlCSEcdDodVIUKIwWAQxzHGOC4xGo2klABg6MU4ODhIkqRcDSaEkFJWjSmlsiw7PDxUSnHOEUJVXEOPxPd9zpiSsZJxFAlYHR5nVEZhHIWckpBRzmBl2uUfQEFwbRgHhOBlMQYeUOFB5NMYsRgzcSsGHGw8+7z25pd59t08+1F7/XP95VdM+XUIAEDe+vMv9y/+2Ze/u+//PLj4e+/VN0I5wajMjFHrybuN00/N06vm6dXm04/m/AMGWuYHgtFO9sKaXzqz8+783J5dWI/fkpK9KZv6Hea2aN+ifYu5FvV2lsumvsNcC/o29G3atyt2sWFACbAloys9E4qBVVax/wERiuTHFpRcSAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3438474753da16d4f5dbe942f095b97b/e4706/Android_Wizard_Installation_Others_d06.avif 230w", "/en/static/3438474753da16d4f5dbe942f095b97b/d1af7/Android_Wizard_Installation_Others_d06.avif 460w", "/en/static/3438474753da16d4f5dbe942f095b97b/7f308/Android_Wizard_Installation_Others_d06.avif 920w", "/en/static/3438474753da16d4f5dbe942f095b97b/e1c99/Android_Wizard_Installation_Others_d06.avif 1380w", "/en/static/3438474753da16d4f5dbe942f095b97b/6e1e4/Android_Wizard_Installation_Others_d06.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3438474753da16d4f5dbe942f095b97b/a0b58/Android_Wizard_Installation_Others_d06.webp 230w", "/en/static/3438474753da16d4f5dbe942f095b97b/bc10c/Android_Wizard_Installation_Others_d06.webp 460w", "/en/static/3438474753da16d4f5dbe942f095b97b/966d8/Android_Wizard_Installation_Others_d06.webp 920w", "/en/static/3438474753da16d4f5dbe942f095b97b/445df/Android_Wizard_Installation_Others_d06.webp 1380w", "/en/static/3438474753da16d4f5dbe942f095b97b/87a8c/Android_Wizard_Installation_Others_d06.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3438474753da16d4f5dbe942f095b97b/81c8e/Android_Wizard_Installation_Others_d06.png 230w", "/en/static/3438474753da16d4f5dbe942f095b97b/08a84/Android_Wizard_Installation_Others_d06.png 460w", "/en/static/3438474753da16d4f5dbe942f095b97b/c0255/Android_Wizard_Installation_Others_d06.png 920w", "/en/static/3438474753da16d4f5dbe942f095b97b/b1001/Android_Wizard_Installation_Others_d06.png 1380w", "/en/static/3438474753da16d4f5dbe942f095b97b/81315/Android_Wizard_Installation_Others_d06.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3438474753da16d4f5dbe942f095b97b/c0255/Android_Wizard_Installation_Others_d06.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[11]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
      <li parentName="ul"><strong parentName="li">{`[12]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/69a3f89e38e02d4739001e755b1e34c1/81315/Android_Wizard_Installation_Others_d07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVQ4y42STYscVRSGaylhqmfEgMTOdHX3dFdX9711v+vWrVv3o76mZxaODFmMiBhGgojELELEBP+CuhDciL/AP6ALF4JbQXcu8l9CpKtmhoigPpzVee85vO/hBkmSzOfz0Wi0v78fhuHBwcF4PAabDUzRm+MoSZLFYhGG4Wg0CsNwb29vMpmsVisI4eRoFSRJslwuq6qSUk6n09lsFkXR4d3x4WwxZifxKol7NcuyKIrm83kUTaNoMplEY/F2ACFECOV5Lnp4jxAiTRGEEACw2WwAAEIIxtiggmuCLMs45865tm27rqvrum3bpmnyPM8yoZQqy1JrPajb7fb4+NgYo3sCY8xZj1IKAIAQSlOUpjClAqvamtJ575zz3p+dnZ2fn9d17a8JrLVN03RdZ4xRShVXqLzQSts8l0opKSVCaAiFMYYQpmm6s10UxbCmrmsp5RB4905kPNPe+9PTU601voYQQinlnBtjAufcOz1d15EddKdjTIQiqvbOtm17c84bpJRlWQacc0ZJWWpnre/jVdXOSMYZZ1RKOcSglJK/gzEOMCEoM7nxuuq0a5Tx2tXaVqm0KabGlNvtibUWIUT+QUAoS3Mf+3ePuo+W7WXcfXjUPVj699K8wpRLmXnvjTE3X2A4StO0ddsFfUr4+uPfXvvqZfTNi+W3L259/XL09DlFEGOc57n3Xmt9Y5tSShljKRDrOBg6a3cv7i7j9oO4vR93l+vqYkhVFIVzzlpbFDpXSpdaZDKjhJycry8+HoYJgwkDCwaWVwVXw7BSyjrXON1aaQtRGaUEcnTN7z+Kv/juahhThil/pdhVnxACVnfe//7WZ8/f+vz3w2d/7D35841Pf0WIseU0IP8OpQyB6OLL2w9/ufvwx+jRT7c/+fnOgx9wpglj/zXcL2Aw5pspW8/oesY3MwaOBk8B/R8QxgkTr9bQ/wux0Ob05+ifgwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69a3f89e38e02d4739001e755b1e34c1/e4706/Android_Wizard_Installation_Others_d07.avif 230w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/d1af7/Android_Wizard_Installation_Others_d07.avif 460w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/7f308/Android_Wizard_Installation_Others_d07.avif 920w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/e1c99/Android_Wizard_Installation_Others_d07.avif 1380w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/6e1e4/Android_Wizard_Installation_Others_d07.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/69a3f89e38e02d4739001e755b1e34c1/a0b58/Android_Wizard_Installation_Others_d07.webp 230w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/bc10c/Android_Wizard_Installation_Others_d07.webp 460w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/966d8/Android_Wizard_Installation_Others_d07.webp 920w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/445df/Android_Wizard_Installation_Others_d07.webp 1380w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/87a8c/Android_Wizard_Installation_Others_d07.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/69a3f89e38e02d4739001e755b1e34c1/81c8e/Android_Wizard_Installation_Others_d07.png 230w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/08a84/Android_Wizard_Installation_Others_d07.png 460w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/c0255/Android_Wizard_Installation_Others_d07.png 920w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/b1001/Android_Wizard_Installation_Others_d07.png 1380w", "/en/static/69a3f89e38e02d4739001e755b1e34c1/81315/Android_Wizard_Installation_Others_d07.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/69a3f89e38e02d4739001e755b1e34c1/c0255/Android_Wizard_Installation_Others_d07.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[13]`}</strong>{` Once your camera is connected click on `}<strong parentName="li">{`Search`}</strong>{` and the app will detect all INSTAR cameras on your local network. If your camera does not show choose to `}<a parentName="li" {...{
          "href": "/en/Software/iOS/InstarVision/iPad/DDNS/"
        }}>{`add it manually`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`[14]`}</strong>{` You will now be asked to enter the admin login of your camera. If you haven't set it up before this will be the default usermane `}<strong parentName="li">{`admin`}</strong>{` and password `}<strong parentName="li">{`instar`}</strong>{`. This can be changed later inside the app.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0116fe4606c9ae43fd5123a21193734/81315/Android_Wizard_Installation_Others_d08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeElEQVQ4y6VRu27UQBS1hChQlLASRIisl317jb32vGc8M7bjR1g2EGyRrOgITYQA5Q+CSAVCFLSp4CMQP8CPpAW+AaQg2wtdRKQc3eY+zpx77hi2bff7/Vartba2trq62mq12u224ziOO71l9iaTyWg0ul6jGeh0OpZlua57Z2gbtm2Px+MsyxBC3W53MBj0en3TbHd6IxPfH1uT4WAQxzHnvOl2u13TNNumeRtvG67rep7HOcd/gRCilPq+7zgOhFAIwRhrBiiljPMqqVMjCAKt9dbWVp7n92rkeT6bzaIoEkFACNFaK6W01vP5vCiKMAzDuiS4MKIoKstyZ2enLMuiKPb29haLRVmWu7u7aZoihJRSUkoIIQAAQogIwZxhwTFjBuc8juMkSUUQQIwoZYRSTAjG2PM8SmmWZWmaEkJgDSqECLWIQialkSTJvMbm5iYAfvU+AL7vE0KklIyxPM+zLKu6ECKEWBCIOKrIQWAopbIsS7I8TjLMBGaCMkEYF0JIKRvPQoh/yogQKgMmJWHM8H3f97ybB1+uHX3fOD7deHO6cvRj/fAbrOR9xnie51Jpv9LFTVCleBgSzg2EqmX6j4839j8Pn50M9k82nn7qLd7VBwKM8SRJolBRBKDvIjBFwGOcMq0q5WYZPJ1gd4icEXJH2B3iqdWclzIWR5HMH3lx4ahtVz9w9UOQFkxrTOmSDBAGiEBMICKgCrysQwTuDm88/3rl/dn6h1/tj7+vvj1bef1zSkPouUvyuUAIQ/9u8mS0fWjNX1jzl+P5K2t2AAiFEPyPvDRlV14qR02Mq50gvBAZ1qYqX0tTy2+7GPkcXI6MLoE/HmzhmBFG/+UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0116fe4606c9ae43fd5123a21193734/e4706/Android_Wizard_Installation_Others_d08.avif 230w", "/en/static/c0116fe4606c9ae43fd5123a21193734/d1af7/Android_Wizard_Installation_Others_d08.avif 460w", "/en/static/c0116fe4606c9ae43fd5123a21193734/7f308/Android_Wizard_Installation_Others_d08.avif 920w", "/en/static/c0116fe4606c9ae43fd5123a21193734/e1c99/Android_Wizard_Installation_Others_d08.avif 1380w", "/en/static/c0116fe4606c9ae43fd5123a21193734/6e1e4/Android_Wizard_Installation_Others_d08.avif 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0116fe4606c9ae43fd5123a21193734/a0b58/Android_Wizard_Installation_Others_d08.webp 230w", "/en/static/c0116fe4606c9ae43fd5123a21193734/bc10c/Android_Wizard_Installation_Others_d08.webp 460w", "/en/static/c0116fe4606c9ae43fd5123a21193734/966d8/Android_Wizard_Installation_Others_d08.webp 920w", "/en/static/c0116fe4606c9ae43fd5123a21193734/445df/Android_Wizard_Installation_Others_d08.webp 1380w", "/en/static/c0116fe4606c9ae43fd5123a21193734/87a8c/Android_Wizard_Installation_Others_d08.webp 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0116fe4606c9ae43fd5123a21193734/81c8e/Android_Wizard_Installation_Others_d08.png 230w", "/en/static/c0116fe4606c9ae43fd5123a21193734/08a84/Android_Wizard_Installation_Others_d08.png 460w", "/en/static/c0116fe4606c9ae43fd5123a21193734/c0255/Android_Wizard_Installation_Others_d08.png 920w", "/en/static/c0116fe4606c9ae43fd5123a21193734/b1001/Android_Wizard_Installation_Others_d08.png 1380w", "/en/static/c0116fe4606c9ae43fd5123a21193734/81315/Android_Wizard_Installation_Others_d08.png 1656w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0116fe4606c9ae43fd5123a21193734/c0255/Android_Wizard_Installation_Others_d08.png",
              "alt": "Android App Installation Wizard",
              "title": "Android App Installation Wizard",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`[15]`}</strong>{` Please choose a name for your camera.`}</li>
      <li parentName="ul"><strong parentName="li">{`[16]`}</strong>{` Click `}<strong parentName="li">{`Next`}</strong>{` to continue.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      